<template>
  <ToggleHeader
    title="Vehicle specification"
    text-colour="has-text-dark"
    :visible="visible"
    @toggle="toggle"
  >
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
      mode="out-in"
    >
      <div v-if="visible && data">
        <hr class="mt-0 has-background-dark" />
        <div class="columns mb-6">
          <div class="column is-12">
            <div
              class="columns is-vcentered is-mobile is-vcentered"
              v-for="(value, name) in specs"
              :key="name"
            >
              <div class="column has-text-weight-light">
                <p>{{ name }}</p>
              </div>
              <div class="column is-narrow has-text-weight-semibold">
                <span>
                  {{ value }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </ToggleHeader>
</template>

<script>
import { getSpec } from '../services'
import { formatDate, timeFromNow } from '@/utils/date.utils.ts'
import { startCase, upperFirst } from 'lodash'

export default {
  name: 'VehicleSpec',
  components: {
    ToggleHeader: () => import('core/components/ToggleHeader')
  },
  props: {
    vrm: {
      type: String,
      required: false,
      default: () => null
    },
    preload: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data: () => ({
    visible: true,
    spec: null
  }),
  watch: {
    vrm: 'fetch'
  },
  computed: {
    data() {
      return this.preload || this.spec
    },
    firstReg() {
      const firstReg = this.data.firstReg || this.data.firstRegistration
      return firstReg ? formatDate(firstReg) : '-'
    },
    engineNumber() {
      const { engineNumber } = this.data
      return !engineNumber || engineNumber === 'N0T KN0WN' ? '-' : engineNumber
    },
    vehicleAge() {
      const firstReg = this.data.firstReg || this.data.firstRegistration
      return firstReg ? upperFirst(timeFromNow(firstReg)) : '-'
    },
    colour() {
      const colour = this.data.currentColour || this.data.colour
      return colour ? startCase(colour) : '-'
    },
    powerOutput() {
      const { powerOutputBhp: bhp, powerOutputKw: kw } = this.data
      if (bhp || kw) return `${bhp || '-'} bhp, ${kw || '-'} kw`
      return '-'
    },
    co2emissions() {
      if (!this.isVpp || !this.$experian) return {}
      const { co2Emissions: co2 } = this.data
      return { 'CO2 Emissions': co2 ? `${co2} g/km` : '-' }
    },
    vin() {
      if (!this.isVpp || !this.$experian) return {}
      return { VIN: this.$store.getters['vpp/vin'] } || '-'
    },
    type() {
      return this.data.type ? { Type: this.data.type } : {}
    },
    specs() {
      const { data } = this
      return {
        ...this.vin,
        ...this.type,
        Manufacturer: data.make || '-',
        Model: data.model || '-',
        Trim: data.trim || '-',
        'Manufacturer year': data.year || '-',
        'First registration': this.firstReg || '-',
        'Body style': data.bodyStyle || '-',
        Transmission: data.transmission || '-',
        Doors: data.doors || '-',
        'Fuel type': data.fuelType || '-',
        'Engine capacity': data.engineCapacity || '-',
        Valves: data.numberValves || '-',
        Cylinders: data.cylinders || data.numberCylinders || '-',
        Gears: data.gears || '-',
        'Engine number': this.engineNumber || '-',
        'Power output': this.powerOutput,
        ...this.co2emissions,
        Colour: this.colour,
        'Vehicle age': this.vehicleAge
      }
    },
    isVpp() {
      return this.$route.name === 'vehicle-profile'
    }
  },
  mounted() {
    this.$mxp.track(`${this.$route.name}_page_load`)
    if (!this.data) this.setSpec()
  },
  methods: {
    async setSpec() {
      if (this.preload) {
        this.spec = this.preload
      } else if (this.vrm) {
        try {
          this.spec = await getSpec(this.vrm)
        } catch {
          this.spec = null
        }
      }
    },
    toggle() {
      const action = this.visible ? 'close' : 'open'
      this.$mxp.track(`${action}_spec_collapsible`)
      this.visible = !this.visible
    }
  }
}
</script>
